import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

import logoIcon from '../../resources/images/logos/lensesio-logo-icon-b-vsmall.png'

class softwareSecurityCertification extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== Software Security Certification ========== --> */}
        <section className="mt-5 py-5 px-2">
          <div className="container-1">
            <div className="row pt-4">
              {/* Legals / Company Name / Address */}
              <div className="col-md-12 pb-5">
                <table style={{ width: '100%' }} className="f-14 mb-5">
                  <tr>
                    <td align="left" className="text-align-right">
                      <b>Lenses.io Ltd</b> is a company registered in England &
                      Wales
                      <br />
                      Company Registered Number 09975716
                      <br />
                      VAT number: GB 231980705
                      <br />
                      Registered office address
                      <br />
                      C/O Corporation Service Company (Uk) Limited, 5 Churchill
                      Place, 10th Floor,
                      <br />
                      London, E14 5HU, United Kingdom,
                    </td>
                  </tr>
                </table>
              </div>

              {/* Purpose of document */}
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={logoIcon}
                    alt="Lenses.io logo icon"
                    className="pb-5"
                  />
                  <br />
                  <br />
                  <br />
                  <p className="f-26 fw-600 roboto pb-5">
                    <span className="primary-text">Lenses ®</span>
                    <span> Enterprise</span>
                    <br />
                    <br />
                    Software Security Certification
                  </p>
                </div>
                <br />
                {/* Executive Summary */}
                <p>
                  The following document outlines the Software Security
                  Certification and policies that Lenses.io is following, to
                  ensure high security standards during Software Development
                  Life Cycle (SDLC), in order to deliver a product that can
                  adhere to the modern security aware organization including
                  leading Financial, Insurance and Healthcare organizations.
                </p>
                <p>
                  The nature of the Software is a Product. The Product is
                  currently not offered as a Service (SaaS) and is hosted
                  internally on Client&apos;s hardware, network and security
                  infrastructure.
                </p>
                <h3 className="pb-4">1. SOURCE CODE</h3>
                <p>
                  <b>1.1</b> All source code of the Software is stored in{' '}
                  <b>GitHub</b>.
                </p>
                <p>
                  <b>1.2</b> Source code access requires a mandatory <b>SSO</b>{' '}
                  process for all Software Engineers and employees.
                </p>
                <p>
                  <b>1.3</b> Source code access requires a mandatory <b>2FA</b>{' '}
                  process for all Software Engineers and employees.
                </p>
                <p>
                  <b>1.4</b> Source code commits are allowed only through a
                  process of raising a <b>PR</b> that needs to be reviewed and
                  accepted by two other independent <b>CODEOWNERS</b> before
                  accepted to be merged.
                </p>
                <p>
                  <b>1.5</b> Source code is automatically scanned and checked
                  against known libraries with vulnerabilities.
                </p>
                <p>
                  <b>1.6</b> Source code for every minor and major release is
                  also published to an independent Software <b>Escrow</b>{' '}
                  service.
                </p>
                <h3 className="pb-4">2. BUILD PROCESS</h3>
                <p>
                  <b>2.1</b> The Software is built fully automated, in a CI/CD
                  system.
                </p>
                <p>
                  <b>2.2</b> The CI/CD process is audited and driven by
                  definitions within the source code of the relevant project.
                </p>
                <p>
                  <b>2.3</b> No access to the CI/CD environment is allowed on a
                  physical level.
                </p>
                <p>
                  <b>2.4</b> The CI/CD system uses in-transit encryption
                  (https).
                </p>
                <p>
                  <b>2.5</b> Access to the CI/CD tooling is allowed only via
                  secure and encrypted VPN connection.
                </p>
                <p>
                  <b>2.6</b> All build artifacts are automatically stored in
                  secure artifact repository systems.
                </p>
                <p>
                  <b>2.7</b> Write access is not allowed on the artifact system,
                  read-only access via <b>SSO</b> and <b>2FA</b>.
                </p>
                <h3 className="pb-4">3. THIRD PARTY LIBRARIES</h3>
                <p>
                  Third party libraries used by the Software are monitored for
                  security vulnerabilities. The complete list of all third-party
                  libraries are reviewed manually as per the release process,
                  and audited and available online at
                  <a href="https://lenses.io/third-party-software/">
                    {' '}
                    https://lenses.io/third-party-software{' '}
                  </a>
                  for every minor and major release of the Software.
                </p>
                <h3 className="pb-4">4. EXTERNAL SECURITY ASSESSMENT</h3>
                <p>
                  The following areas of the Product are externally audited via
                  an InfoSec process in terms of penetration testing elements of
                  the{' '}
                </p>
                <p>
                  <b>4.1</b> Authentication
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.1.1</b> Authentication standard methods;
                  </li>
                  <li>
                    <b>4.1.2</b> Password storage;
                  </li>
                  <li>
                    <b>4.1.3</b> Encryption of sensitive data;
                  </li>
                  <li>
                    <b>4.1.4</b> Authorization header;
                  </li>
                </ul>
                <p>
                  <b>4.2</b> Token Generation
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.2.1</b> Token generation keys;
                  </li>
                  <li>
                    <b>4.2.2</b> Time stamps;
                  </li>
                  <li>
                    <b>4.2.3</b> Token expiration (TTL, RTTL);
                  </li>
                  <li>
                    <b>4.2.4</b> Algorithms;
                  </li>
                </ul>
                <p>
                  <b>4.3</b> Access
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.3.1</b> Request throttling for brute-force attacks;
                  </li>
                  <li>
                    <b>4.3.2</b> Man in the middle attacks;
                  </li>
                  <li>
                    <b>4.3.2</b> Local cache and cookie storage;
                  </li>
                </ul>
                <p>
                  <b>4.4</b> Input
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.4.1</b> HTTP methods;
                  </li>
                  <li>
                    <b>4.4.2</b> Content-type validation;
                  </li>
                  <li>
                    <b>4.4.3</b> User input validation;
                  </li>
                  <li>
                    <b>4.4.4</b> Sensitive parameters passed in URLs;
                  </li>
                </ul>
                <p>
                  <b>4.5</b> Injection
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.5.1</b> Cross site scripting;
                  </li>
                  <li>
                    <b>4.5.2</b> SQL injections;
                  </li>
                  <li>
                    <b>4.5.3</b> Blind XPATH injections;
                  </li>
                  <li>
                    <b>4.5.4</b> LDP injections;
                  </li>
                </ul>
                <p>
                  <b>4.5</b> Broken authentication
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.5.1</b> Trust boundary violation;
                  </li>
                  <li>
                    <b>4.5.2</b> Session expiration;
                  </li>
                  <li>
                    <b>4.5.3</b> Race conditions;
                  </li>
                  <li>
                    <b>4.5.4</b> Clickjacking/Frameable Responses;
                  </li>
                </ul>
                <p>
                  <b>4.6</b> Excessive Data Exposure
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.6.1</b> Unencrypted soap messages;
                  </li>
                  <li>
                    <b>4.6.2</b> Hardcoded salts;
                  </li>
                  <li>
                    <b>4.6.3</b> Hardcoded passwords;
                  </li>
                  <li>
                    <b>4.6.4</b> Inadequate padding;
                  </li>
                  <li>
                    <b>4.6.5</b> Weak cryptography (length);
                  </li>
                  <li>
                    <b>4.6.6</b> Weak cryptography hashes (RSA or AES);
                  </li>
                  <li>
                    <b>4.6.7</b> Personal Identifiable Information (PII);
                  </li>
                </ul>
                <p>
                  <b>4.7</b> XML external entities
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.7.1</b> XML entity injection;
                  </li>
                  <li>
                    <b>4.7.2</b> XML entity expansion (&ldquo;billion laughs
                    attack&rdquo;);
                  </li>
                  <li>
                    <b>4.7.3</b> Server side request forgery;
                  </li>
                </ul>
                <p>
                  <b>4.8</b> Broken Access Control
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.8.1</b> Access restrictions;
                  </li>
                  <li>
                    <b>4.8.2</b> Access control (LDAP Bind);
                  </li>
                  <li>
                    <b>4.8.3</b> Server side file disclosures;
                  </li>
                </ul>
                <p>
                  <b>4.9</b> Broken function level authorization
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.9.1</b> Hierarchies and groups;
                  </li>
                  <li>
                    <b>4.9.2</b> User functionality and user roles;
                  </li>
                  <li>
                    <b>4.9.3</b> Service accounts authorization;
                  </li>
                  <li>
                    <b>4.9.4</b> Account lockout;
                  </li>
                </ul>
                <p>
                  <b>4.10</b> Mass Assignment
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.10.1</b> Restricted object properties;
                  </li>
                  <li>
                    <b>4.10.2</b> Authorization scope of the request;
                  </li>
                </ul>
                <p>
                  <b>4.11</b> Security misconfiguration
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.11.1</b> HTTP security headers;
                  </li>
                  <li>
                    <b>4.11.2</b> Cross-origin resource sharing (CORS);
                  </li>
                </ul>
                <p>
                  <b>4.12</b> Improper Assets Management
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.12.1</b> Improper versioning control;
                  </li>
                  <li>
                    <b>4.12.2</b> Incomplete or outdated assets;
                  </li>
                  <li>
                    <b>4.12.3</b> Unpatched older versions;
                  </li>
                </ul>
                <p>
                  <b>4.13</b> Insufficient Logging & Monitoring
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.13.1</b> Multi stage attacks;
                  </li>
                  <li>
                    <b>4.13.2</b> Unusual activity;
                  </li>
                  <li>
                    <b>4.13.3</b> Incident response;
                  </li>
                </ul>
                <p>
                  <b>4.14</b> Information Disclosure
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.14.1</b> Log Data Analysis for Information Disclosure
                    (no sensitive info leaking in logs);
                  </li>
                </ul>
                <p>
                  <b>4.15</b> JVM app checks
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>4.15.1</b> DDoS;
                  </li>
                  <li>
                    <b>4.15.2</b> BufferOverFlows;
                  </li>
                  <li>
                    <b>4.15.3</b> Mounting files from host allowing for Remote
                    Code Executions / Arbitrary File Uploads / Code Executions;
                  </li>
                  <li>
                    <b>4.15.4</b> Code Injection;
                  </li>
                </ul>
                All issues are classified based on a severity scoring mechanism
                as HIGH, MEDIUM, LOW or BEST PRACTISE. All HIGH and MEDIUM
                reports addressed at a high priority. HIGH severity threats are
                communicated to affected customers.
                {/*
                <h3 className="pb-4">5. INCIDENT RESPONSE</h3>
                <p>All high security threats such as zero-day exploits are communicated with customers once evaluated.</p>
                */}
                <h3 className="pb-4">5. REFERENCES </h3>
                <ul className="list-style-none2">
                  <li>
                    <a
                      href="https://www.pcisecuritystandards.org/documents/PCI_DSS_v3-2.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PCI DSS v3.2
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.owasp.org/index.php/Brute_force_attack"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OWASP - Brute Force Attack
                    </a>
                  </li>
                  <li>
                    <a href="https://www.owasp.org/index.php/Clickjacking">
                      OWASP - Clickjacking
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.owasp.org/index.php/Session_Management_Cheat_Sheet#Simultaneous_Session_Logons"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OWASP - Session management
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.symantec.com/connect/articles/auditing-web-site-authentication-part-two"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Auditing Web Site Authentication
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://cwe.mitre.org/data/definitions/307.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CWE-307: Improper Restriction of Excessive Authentication
                      Attempts
                    </a>
                  </li>
                </ul>
                <hr className="pt-2" />
                <p className="pt-2">
                  Last update: 11 Oct 2021
                  <br />
                  <br />
                  <small>
                    {' '}
                    The Software Security Certification document may be updated
                    from time to time, with the understanding that any such
                    updates will not materially reduce the terms and conditions
                    experienced by the Customer.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default softwareSecurityCertification

export const pageQuery = graphql`
  query legalsSoftwareSecurityCertificationSeo {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "Legals_Policy" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
